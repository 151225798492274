import React from 'react';
import '../../../App.js';

function Gallery_OpenHearted() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Open Hearted - (A4)  </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/openHearted.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Acrylic painting inspired by an unusual perspective of a heart shown in a musuem </p>
        </center>
        </>
    )

}

export default Gallery_OpenHearted
