import React from 'react';
import '../../../App.js';

function Gallery_Skeleton() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Berries study - (A1)</h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/berry.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Hand draw with pen and oil pastels </p>
        </center>
        </>
    )

}

export default Gallery_Skeleton
