import React from 'react';
import '../../../App.js';

function Gallery_Torso() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Torso Study - (A3) </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/torsoStudy.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Graphite pencil study of the torso in a boroque syle </p>
        </center>
        </>
    )

}

export default Gallery_Torso
