import React from 'react';
import '../../../App.js';

function Gallery_Blue() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Blue </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/blue.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Oil painting on MDF board to explore depth perspective </p>
        </center>
        </>
    )

}

export default Gallery_Blue
