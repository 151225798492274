import React from 'react';
import '../../../App.js';

function Gallery_AllEyesOnYou() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> All in your head - (A4 x 9) </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/allEyesOnYou.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Three different methods of printing were used to create this piece of acrylic print. Mono printing, Lino printing and screen combined to create a series of prints. </p>
        </center>
        </>
    )


}

export default Gallery_AllEyesOnYou
