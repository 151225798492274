import React from 'react';
import '../../../App.js';

function Gallery_LostMyself() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Skeleton Artwork </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/lostMyself.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Oil and acrylic painting on MDF board </p>
        </center>
        </>
    )

}

export default Gallery_LostMyself
