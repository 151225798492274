import React from 'react';
import '../../App.css';
import Cards from '../Cards.js';

function Gallery() {
    return (
        <>
        <Cards />
        </>
    ) 
}

export default Gallery
