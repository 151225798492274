import React from 'react';
import '../../../App.js';

function Gallery_Distort() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Distort - (A1) </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/distort.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Three hand drawn prints on one plane to create a moving effect when different coloured lights are applied using the samoiloff effect </p>
        </center>
        </>
    )

}

export default Gallery_Distort
