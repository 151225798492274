import React from 'react';
import '../../../App.js';

function Gallery_HandPink() {
    return (
        <>
        <h1 style={{fontSize:"3em"}}> Pink hand painting - (A1) </h1>
        <img src='https://raw.githubusercontent.com/SherllyNeo/CoxonArtworks/main/public/images/handPrint2.jpeg' alt="image not found" width="100%" height="100%" class="center"/>
        <center>
        <p style={{fontSize:"2em"}}> Hand print in acrylic paint to prepare for the samoiloff effect piece 'Distort' </p>
        </center>
        </>
    )

}

export default Gallery_HandPink
